<script setup lang="ts">
import { useDark, useToggle } from '@vueuse/core'

withDefaults(defineProps<{
  support?: boolean
  privacy?: boolean
  imprint?: boolean
  github?: boolean | string
  githubUrl?: string
  darkMode?: boolean
  mastodon?: boolean
  license?: boolean
  linkHoltwick?: boolean
  rss?: boolean
}>(), {
  imprint: true,
  privacy: true,
})

const isDark = useDark()
const toggleDark = useToggle(isDark)
</script>

<template>
  <footer class="app-footer">
    <div class="app-footer-left">
      <template v-if="linkHoltwick">
        <a href="https://holtwick.de" target="_blank">&copy;&nbsp;Dirk&nbsp;Holtwick</a>
      </template>
      <template v-else>
        &copy;&nbsp;Dirk&nbsp;Holtwick
      </template>
    </div>
    <nav class="app-footer-right">
      <ul>
        <slot />
        <li v-if="imprint">
          <router-link class="app-header-menu" :to="`/${$i18n.locale}/imprint`">
            {{ $t("site.imprint") }}
          </router-link>
        </li>

        <li v-if="privacy">
          <router-link class="app-header-menu" :to="`/${$i18n.locale}/privacy`">
            {{ $t("site.privacy") }}
          </router-link>
        </li>

        <li v-if="license">
          <router-link class="app-header-menu" :to="`/${$i18n.locale}/license`">
            {{ $t("license.title") }}
          </router-link>
        </li>

        <li v-if="support">
          <router-link class="app-header-menu" :to="`/${$i18n.locale}/support`">
            {{ $t("support.title") }}
          </router-link>
        </li>

        <li v-if="github">
          <a :href="githubUrl ?? 'https://github.com/holtwick'" rel="noopener noreferrer" target="_blank">GitHub</a>
        </li>

        <li v-if="mastodon">
          <a href="https://mastodon.social/@holtwick" rel="me" target="_blank">Mastodon</a>
        </li>

        <li v-if="darkMode">
          <a href="#toggleDarkMode" @click="toggleDark()">{{ isDark ? "Light" : "Dark" }} Mode</a>
        </li>

        <li v-if="rss">
          <a :href="$i18n.locale === 'en' ? '/feed.xml' : `/${$i18n.locale}/feed.xml`" rel="noopener noreferrer" type="application/rss+xml" target="_blank">RSS</a>
        </li>
      </ul>
    </nav>
  </footer>
</template>
