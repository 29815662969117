<script setup lang="ts">
import { ArrowDownToLine, CircleHelp, CircleUser, Coins } from 'lucide-vue-next'
import { OuiMobileActivator, OuiTooltipActivator } from 'oui-kit'
import logo from './_assets/logo.png'
import logo2x from './_assets/logo@2x.png'
import AppFooter from '@/components/app-footer.vue'
import AppHeader from '@/components/app-header.vue'
import { useAppHeadHoltwick } from '@/lib/use-app-head-holtwick'
import { useExternalLinks } from '@/lib/use-external-links'

import './app.styl'

useAppHeadHoltwick()
useExternalLinks()
</script>

<template>
  <AppHeader title="Receipts" :logo="logo" :logo2x="logo2x">
    <router-link class="app-header-menu" :to="`/${$i18n.locale}/help`">
      <CircleHelp />
      <span>{{ $t("index.help") }}</span>
    </router-link>
    <router-link class="app-header-menu" :to="`/${$i18n.locale}/support`">
      <CircleUser />
      <span>{{ $t("support.title") }}</span>
    </router-link>
    <router-link class="app-header-menu" :to="`/${$i18n.locale}/buy`">
      <Coins />
      <span>{{ $t("index.buy") }}</span>
    </router-link>
    <a class="app-header-menu" href="/latest">
      <ArrowDownToLine />
      <span>{{ $t("index.download") }}</span>
    </a>
  </AppHeader>
  <template v-if="$route.meta.pure">
    <router-view />
  </template>
  <template v-else>
    <main class="app-content">
      <Suspense>
        <router-view />
      </Suspense>
    </main>
  </template>
  <AppFooter
    link-holtwick
    support
    imprint
    privacy
    license
  >
    <li>
      <RouterLink :to="`/${$i18n.locale}/changelog`">
        Changelog
      </RouterLink>
    </li>
    <li>
      <RouterLink :to="`/${$i18n.locale}/social`">
        Social
      </RouterLink>
    </li>
  </AppFooter>
  <ClientOnly>
    <OuiTooltipActivator />
    <OuiMobileActivator />
  </ClientOnly>
</template>
