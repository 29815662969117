import type { RouteRecordRaw } from 'vue-router'
import type { PostMeta, SiteConfig } from './_types'

export function generatePages(files: Record<string, () => Promise<unknown>>, subPath: string = '', meta?: Record<string, any>): RouteRecordRaw[] {
  const routes = []
  for (const [filepath, comp] of Object.entries(files)) {
    const parts = filepath.split('/')
    const name = parts[parts.length - 1].replace('.md', '')
    const slug = name.slice(0, -3)
    const lang = name.slice(-2)
    let path = `/${lang}`
    if (subPath)
      path += `/${subPath}`
    if (slug !== 'index')
      path += `/${slug}`
    routes.push({
      path,
      meta: {
        ...meta,
        canonical: path,
        comp,
      },
      component: () => import(`@/components/app-page-async.vue`), // same as app-post
    })
  }
  return routes
}

export function getRoutesStandard(opt: {
  siteConfig: SiteConfig
  pagesImport: Record<string, () => Promise<unknown>>
  postsImport?: Record<string, () => Promise<unknown>>
  postsData?: PostMeta[]
  routes?: RouteRecordRaw[]
}) {
  const { routes = [], postsData, siteConfig, postsImport } = opt

  // Pages
  if (opt.pagesImport) {
    routes.push(...generatePages(
      opt.pagesImport,
      undefined,
      { mode: 'page' },
    ))
  }

  // Posts
  if (postsData?.length && postsImport && siteConfig.postsPath) {
    routes.push(...generatePages(
      postsImport,
      siteConfig.postsPath,
      { mode: 'post' },
    ))
  }

  // import.meta.glob('./_posts/*.md', { eager: false }

  for (const lang of siteConfig.languages) {
    // Posts listing
    if (postsData) {
      routes.push({
        path: `/${lang}/${siteConfig.postsPath}`,
        meta: { postsData },
        component: () => import('@/pages/posts.vue'),
      })
    }

    // Posts support
    routes.push({
      path: `/${lang}/support`,
      component: () => import('@/pages/support.vue'),
    })
  }

  // 404
  routes.push({
    path: '/404',
    name: 'not-found',
    component: () => import('@/pages/404.vue'),
  })

  // Not found -> redirect
  routes.push({
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    beforeEnter: (route) => {
      if (typeof navigator === 'undefined')
        return
      let lang = navigator?.language?.slice(0, 2).toLowerCase()
      if (!globalThis.siteConfig.languages?.includes(lang))
        lang = 'en'

      if (!(route.path.startsWith('/en/') || route.path.startsWith('/de/'))) {
        let newRoute = `/${lang}${route.fullPath}`
        if (newRoute.endsWith('/'))
          newRoute = newRoute.slice(0, -1)

        for (const r of routes) {
          if (newRoute.startsWith(r.path)) // hack: avoids issues with hash and query
            return newRoute
        }
      }
    },
    component: () => import('@/pages/404.vue'),
  })

  // routes.push(...generatePages({
  //   'imprint-en': () => import(`@/../web-holtwick/src/_pages/imprint-en.md`),
  //   'imprint-de': () => import(`@/../web-holtwick/src/_pages/imprint-de.md`),
  //   'privacy-en': () => import(`@/../web-holtwick/src/_pages/privacy-en.md`),
  //   'privacy-de': () => import(`@/../web-holtwick/src/_pages/privacy-de.md`),
  // }))

  return routes
}

export function routesImprintAndPrivacy() {
  return generatePages({
    'imprint-en': () => import(`@/../web-holtwick/src/_pages/imprint-en.md`),
    'imprint-de': () => import(`@/../web-holtwick/src/_pages/imprint-de.md`),
    'privacy-en': () => import(`@/../web-holtwick/src/_pages/privacy-en.md`),
    'privacy-de': () => import(`@/../web-holtwick/src/_pages/privacy-de.md`),
  })
}

export function routesLicense() {
  return generatePages({
    'license-en': () => import(`@/../web-holtwick/src/_pages/license-en.md`),
    'license-de': () => import(`@/../web-holtwick/src/_pages/license-de.md`),
  })
}
