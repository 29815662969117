<script setup lang="ts">
import { useDark, useToggle, useWindowScroll } from '@vueuse/core'
import { LayoutGrid, Moon, Sun } from 'lucide-vue-next'
import type { OuiMenuItem } from 'oui-kit'
import { OuiFloat } from 'oui-kit'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import svgLogo from '../assets/ho.svg'
import messages from './app-header.locale.json'
import AppSearch from './app-search.vue'

import 'oui-kit/css'
import './app-header.styl'

withDefaults(defineProps<{
  title?: string
  logo?: string
  logo2x?: string
  apps?: boolean | undefined
  search?: boolean | undefined
  help?: boolean
  theme?: boolean
  language?: boolean
  rounded?: boolean
}>(), {
  apps: true,
  search: true,
  theme: true,
  language: true,
})

const route = useRoute()
const router = useRouter()

const { locale, t } = useI18n({ messages })

const isDark = useDark()
const toggleDark = useToggle(isDark)

const {
  name,
  postsPath,
} = globalThis.siteConfig

const otherLangRoute = computed(() => {
  const newPrefix = `/${locale.value === 'de' ? 'en' : 'de'}`
  let newPath = `${newPrefix}${(route.meta.canonical as string ?? route.path).slice(3)}`

  // fixes trailing slash; depends on: ssgOptions: {dirStyle: "nested"}
  // if (!newPath.endsWith("/")) newPath = newPath + '/'
  if (newPath.endsWith('/'))
    newPath = newPath.slice(0, -1)

  // log('newPath', newPath)

  if (router.getRoutes().map(r => r.path).includes(newPath))
    return newPath

  return newPrefix
})

const appsElement = ref()

const items = computed<OuiMenuItem[]>(() => [
  {
    title: 'Receipts',
    action: () => location.assign('https://receipts-app.com'),
    url: 'https://receipts-app.com',
    close: true,
    disabled: name === 'receipts',
  },
  {
    title: 'PDFify',
    action: () => location.assign('https://pdfify.app'),
    url: 'https://pdfify.app',
    close: true,
    disabled: name === 'pdfify',
  },
  {
    title: 'Briefing',
    action: () => location.assign('https://about.brie.fi'),
    url: 'https://about.brie.fi',
    close: true,
    disabled: name === 'briefing',
  },
  // {},
  // {
  //   title: 'Alle Projekte',
  //   action: () => location.assign('https://holtwick.de/de/projects'),
  //   url: 'https://holtwick.de/de/projects',
  //   close: true,
  //   disabled: name === 'holtwick',
  // },
])

// const showAppsMenu = ref(false)

const { y } = useWindowScroll()
</script>

<template>
  <header class="app-header" :class="{ _scrolled: y !== 0 }">
    <nav class="app-header-inner">
      <div class="app-header-title" :class="{ 'app-header-title-rounded': rounded }">
        <router-link :to="`/${$i18n.locale}`" aria-label="Homepage">
          <slot name="logo">
            <img v-if="logo" width="48" height="48" :src="logo" :srcset="logo2x ? `${logo2x} 2x` : undefined" alt="Logo">
            <svgLogo v-else alt="Logo" width="48" height="48" />
          </slot>
          <div class="app-header-title-text">
            <slot name="title">
              {{ title ?? '' }}
            </slot>
          </div>
        </router-link>
      </div>

      <div class="app-header-nav">
        <slot>
          <slot name="menu">
            <router-link class="app-header-menu" :to="`/${locale}/${postsPath}`">
              {{ t("posts.title") }}
            </router-link>

            <router-link class="app-header-menu" :to="`/${locale}/projects`">
              {{ t("projects.title") }}
            </router-link>
          </slot>
        </slot>
      </div>

      <div class="app-header-helpers">
        <ClientOnly>
          <!-- <button v-if="apps" ref="appsElement" class="app-header-menu" aria-label="Other Projects">
            <LayoutGrid alt="Other Projects" />
          </button>

          <OuiMenu
            v-if="apps"
            v-model="showAppsMenu"
            hover
            :reference="appsElement"
            :items="items"
          /> -->

          <template v-if="apps">
            <OuiFloat
              placement="bottom"
              :offset="10"
              close
              hover
              arrow
              class="oui-float _dropdown"
              transition="oui-float-transition"
            >
              <template #trigger>
                <button ref="appsElement" class="app-header-menu" aria-label="Other Projects">
                  <LayoutGrid alt="Other Projects" />
                </button>
              </template>

              <div class="app-header-apps">
                <div class="_intro">
                  {{ t("header.appsIntro") }}
                </div>
                <template v-for="item in items" :key="item.url">
                  <a :href="item.url" target="_blank">{{ item.title }}</a>
                </template>
              </div>
            </OuiFloat>
          </template>

          <AppSearch v-if="search" :title="t('header.toggleSearch')" />

          <button v-if="theme" class="app-header-menu" aria-label="Switch Dark Mode" :tooltip="t('header.toggleDarkMode')" @click="toggleDark()">
            <Sun v-if="isDark" alt="Light Mode" />
            <Moon v-else alt="Dark Mode" />
          </button>
        </ClientOnly>

        <router-link v-if="language" class="app-header-menu app-header-menu-locale" :to="otherLangRoute" aria-label="Change Language" :tooltip="t('header.switchLanguage')" :data-lang="locale" :data-path="route.path">
          <slot name="language">
            <!-- <Languages /> -->
            {{ locale === 'de' ? 'EN' : 'DE' }}
          </slot>
        </router-link>
      </div>
    </nav>
  </header>
</template>
